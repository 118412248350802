import React from 'react'
import { Tooltip } from 'react-tooltip'

import Button from '@components/button'

import BoostIcon from '@common/icons/BoostIcon'
import CariIcon from '@common/icons/CariIcon'
import CommentIcon from '@common/icons/CommentIcon'

type Props = {
  isBoosVisible: boolean
  canRepost: boolean
  status: string
  onClickCari: VoidFunction
  onClickBoost: VoidFunction
  onClickRepost: VoidFunction
  onClickComment: VoidFunction
  disableBoostButton?: boolean
  index: number
}

const JobCardFooter = (props: Props) => {
  const {
    canRepost,
    isBoosVisible,
    status,
    onClickCari,
    onClickBoost,
    onClickRepost,
    onClickComment,
  } = props
  const isExpired = status === 'Expired'
  const isActive = status === 'Active'
  if (isExpired && canRepost) {
    return (
      <div className="p-2">
        <Button
          type="button"
          appearance="outline-primary"
          className="w-full"
          onClick={(e) => {
            e.preventDefault()
            onClickRepost()
          }}
        >
          Tayangkan ulang
        </Button>
      </div>
    )
  }

  if (isActive) {
    return (
      <div className="flex items-start w-full justify-around border-t-[1px] border-neutral-gray-100 bg-white">
        <Button
          block
          type="button"
          appearance="text"
          className="items-center rounded-none overflow-hidden"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onClickCari()
          }}
        >
          <CariIcon />
          <span>Cari</span>
        </Button>

        {isBoosVisible && (
          <>
            <Button
              id={`button_boost_jl_${props.index}`}
              block
              type="button"
              appearance="text"
              className="items-center rounded-none overflow-hidden"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClickBoost()
              }}
              disabled={props.disableBoostButton}
              {...(props.disableBoostButton && { noHover: true })}
            >
              <BoostIcon />
              <span>Boost</span>
            </Button>
            {props.disableBoostButton && (
              <Tooltip
                className="z-[5] !opacity-100 !bg-primary-black !shadow-elevation-8 !rounded-[6px]"
                positionStrategy="fixed"
                classNameArrow="!hidden"
                place="bottom-start"
                anchorSelect={`#button_boost_jl_${props.index}`}
              >
                <p className="text-[12px] leading-[16px]">
                  Anda hanya dapat melakukan Boost 1 kali untuk loker ini
                </p>
              </Tooltip>
            )}
          </>
        )}

        <Button
          block
          type="button"
          appearance="text"
          className="items-center rounded-none overflow-hidden"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onClickComment()
          }}
        >
          <CommentIcon />
          <p>Komentar</p>
        </Button>
      </div>
    )
  }

  return null
}

export default JobCardFooter
