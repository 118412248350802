import React from 'react'

const CommentIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.2231 13C20.0224 14.0347 19.6065 15.0157 19.0022 15.8793C18.398 16.7429 17.6189 17.4699 16.7156 18.013C15.8123 18.5561 14.8049 18.9033 13.7587 19.032C12.7126 19.1606 11.6511 19.068 10.6431 18.76C10.4363 18.6851 10.2099 18.6851 10.0031 18.76L7.5031 20C7.4271 20.0377 7.34183 20.0526 7.25755 20.0429C7.17327 20.0333 7.09357 19.9995 7.02806 19.9456C6.96255 19.8917 6.91401 19.82 6.88829 19.7392C6.86257 19.6583 6.86077 19.5718 6.8831 19.49L7.4131 17.35C7.44462 17.208 7.44202 17.0605 7.40552 16.9197C7.36901 16.7789 7.29964 16.6488 7.2031 16.54C6.35297 15.5836 5.76093 14.4262 5.483 13.1771C5.20507 11.9281 5.25045 10.6288 5.61484 9.40225C5.97923 8.17566 6.65058 7.06234 7.56536 6.16765C8.48014 5.27295 9.60809 4.62647 10.8425 4.28939C12.0768 3.95231 13.3768 3.93578 14.6194 4.24136C15.8619 4.54694 17.0059 5.16453 17.9432 6.03567C18.8804 6.90682 19.5798 8.00271 19.9753 9.21963C20.3708 10.4366 20.4492 11.7343 20.2031 12.99L20.2231 13Z"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CommentIcon
