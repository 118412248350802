import { Switch } from '@headlessui/react'
import { get, post } from '@helpers/fetch-wrapper'
import cn from '@helpers/utils'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import React from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { useApplicationsActions } from '@features/applications/reducers/ApplicationsReducer'
import usePriceList from '@features/coins/hooks/usePriceList'
import Chip from '@features/jobs/components/chip/Chip'
import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { jobActions, useJobsActions } from '@features/jobs/job-detail/reducers'
import { useJobListingStateQueries } from '@features/jobs/job-detail/reducers/selectors'
import { updateJobDetail } from '@features/jobs/job-detail/reducers/thunk-actions'
import {
  userKycInReview,
  userKycPending,
  userKycRejected,
} from '@features/kyc/helpers'
import {
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import {
  BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN,
  CLICK_ON_REPOST_JOB,
  COINS_DEDUCTION_ATTEMPT,
  COINS_DEDUCTION_FAILED,
  COINS_DEDUCTION_INSUFFICIENT_BALANCE,
  JOB_SEARCH_ACTIVATE_JOB_LISTING,
  JOB_SEARCH_CLICK_CARI_KANDIDAT,
  JOB_SEARCH_DEACTIVATE_JOB_LISTING,
} from '@constants/event-tracking'
import { JobStatus } from '@constants/job-statuses'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import {
  COINS_BALANCE_URL,
  EMPLOYERS_PROFILE_URL,
  INITIALIZE_URL,
} from '@constants/url'

import { formatDateInID } from '@utils/date-calculation'
import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

import { MapMarkerIcon } from '@common/icons'

import { useCoinsActions } from '@store/coins/slice'

import JobBadge from '../job-badge'
import JobCardFooter from '../job-card-footer/JobCardFooter'
import JobCardMedia from '../job-card-media/JobCardMedia'
import JobCardMenuToggle from '../job-card-menu-toggle/JobCardMenuToggle'
import JobCardQuota from '../job-card-quota/JobCardQuota'
import { JobListingCardProps } from '../job-listing-card/types'

const JobCard = ({ jobPostData, index }: JobListingCardProps) => {
  const {
    id,
    title,
    requiredGender,
    status,
    expiryAt,
    location,
    minimumAge,
    maximumAge,
    minimumSalary,
    maximumSalary,
    medias,
    productPackage,
    isFree,
    canRepost,
    companyName,
    metric,
    placementLocations,
  } = jobPostData

  const { data: initializeData } = useSWRImmutable<{
    featureToggle: {
      canBoost: boolean
      staticLocation: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    const response = await post(url)
    return response.data
  })
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { setJobApplicantsPopupFilters } = useApplicationsActions()
  const newPostHighlight = useAppSelector(
    (state) => state.jobReducer.newPostHighlight
  )

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const companyId = employerProfileData?.company?.id || ''
  const eventProperties = {
    category: employerProfileData?.isPhantom ? 'MS' : 'JobProvider',
    employerId: employerProfileData?.id,
  }
  const { priceListData } = usePriceList()
  const { setProductCode } = useCoinsActions()
  const productCode = 'JobPost:Repost'
  const repostJobPostPrice =
    priceListData?.find(
      (product) => product.productCode === 'JobPost:Repost' && product.isVisible
    )?.price || null

  const jobListingQueries = useJobListingStateQueries()
  const { setSelectJobId } = useApplicationsActions()
  const { mutate } = useJobListingQuery({
    companyId,
    query: jobListingQueries,
  })
  const jobsActions = useJobsActions()

  const changeJobStatus = async (jobId: string, currentStatus: JobStatus) => {
    const isJobActive = currentStatus === 'Active'

    try {
      await dispatch(
        updateJobDetail({
          jobId,
          isActive: !isJobActive,
        })
      ).unwrap()

      if (isJobActive) {
        trackEvents(JOB_SEARCH_DEACTIVATE_JOB_LISTING, eventProperties)
      } else {
        trackEvents(JOB_SEARCH_ACTIVATE_JOB_LISTING, eventProperties)
      }

      toast.success(
        `${isJobActive ? 'Loker berhasil di non-aktifkan!' : 'Loker berhasil diaktifkan!'}`,
        {
          hideProgressBar: true,
          style: {
            backgroundColor: '#52B788',
            color: '#FFFFFF',
          },
        }
      )
      mutate()
      dispatch(appActions.removeModal())
    } catch (changeJobError) {
      toast.error(errorFormat(changeJobError))
    }
  }

  const handleJobStatusChange = () => {
    if (
      status === 'InActive' &&
      productPackage?.passiveApplicantQuota !== -1 &&
      metric?.passiveApplicantCount >= productPackage?.passiveApplicantQuota
    ) {
      setSelectJobId(id)
      dispatch(appActions.setModalName(MODAL_NAME.ASK_TO_UPGRADE))
      return
    }
    if (!employerProfileData || !employerProfileData.company) return
    if (
      userKycPending({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      }) ||
      userKycRejected({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
      return
    }
    if (
      userKycInReview({
        companyKyc: employerProfileData.company.kycStatus,
        employerKyc: employerProfileData.kycStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }
    if (status === 'Active') {
      dispatch(appActions.setModalName(MODAL_NAME.QUESTION_MODAL))
      dispatch(jobActions.setModalId(id))
    }
    if (status !== 'Active') {
      changeJobStatus(id, status)
    }
  }

  const handleClickCari = () => {
    if (status !== 'Active') {
      dispatch(
        appActions.setModalName(MODAL_NAME.CANDIDATE_SEARCH_NOTICE_MODAL)
      )
    } else {
      trackEvents(JOB_SEARCH_CLICK_CARI_KANDIDAT, {
        entry_point: 'Loker',
      })

      setJobApplicantsPopupFilters({
        genders:
          requiredGender !== 'Both' && requiredGender != null
            ? requiredGender
            : undefined,
        homeAddresses: location?.shortAddress
          ? [
              {
                cityName: (location?.shortAddress as string) ?? undefined,
              },
            ]
          : undefined,
        minAge: minimumAge ? Number(minimumAge) : undefined,
        maxAge: maximumAge ? Number(maximumAge) : undefined,
        minSalary: minimumSalary ?? undefined,
        maxSalary: maximumSalary ?? undefined,
      })

      router.push({
        pathname: PATHS.SEARCH_CANDIDATE,
        query: {
          jid: id,
        },
      })
    }
  }

  const handleClickRepost = async () => {
    trackEvents(CLICK_ON_REPOST_JOB, eventProperties)
    if (repostJobPostPrice !== null) {
      const getCoinsBalanceResponse = await get(COINS_BALANCE_URL)
      trackEvents(COINS_DEDUCTION_ATTEMPT, {
        product: productCode,
      })
      const coinsBalanceData: { balance: number } = getCoinsBalanceResponse.data

      if (coinsBalanceData.balance < repostJobPostPrice) {
        dispatch(appActions.setModalName(MODAL_NAME.NOT_ENOUGH_COIN))
        setProductCode(productCode)
        trackEvents(COINS_DEDUCTION_INSUFFICIENT_BALANCE, {
          product: productCode,
        })
        trackEvents(COINS_DEDUCTION_FAILED, {
          product: productCode,
        })
        return
      }
      dispatch(jobActions.setModalId(id))
      dispatch(appActions.setModalName(MODAL_NAME.CHARGE_REPOST_JOB))
      return
    }

    dispatch(appActions.setModalName(MODAL_NAME.REPOST_MODAL))
    dispatch(jobActions.setModalId(id))
  }

  const handleClickBoost = () => {
    if (status !== 'Active') {
      dispatch(appActions.setModalName(MODAL_NAME.BOOST_NOTICE_MODAL))
      return
    }

    if (
      userKycInReview({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
    } else if (
      userKycRejected({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL))
    } else {
      trackEvents(BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN)
      router.push({
        pathname: '/boost',
        query: {
          jid: id,
        },
      })
    }
  }

  const handleClickComment = () => {
    dispatch(
      jobActions.setJobDetailDrawerState({
        jobId: id,
        open: true,
        selectedTab: 'comment',
      })
    )
  }
  const isSwitchShown =
    status === 'Active' || status === 'InActive' || status === 'Pending'

  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'rounded-lg',
        'border',
        'border-[1px]',
        'border-neutral-gray-100',
        'md:mb-0',
        'overflow-auto',
        status === 'Rejected'
          ? 'bg-sidebar-gray'
          : 'bg-white hover:shadow-elevation-16',
        router?.query?.repost && index === 0 ? newPostHighlight : ''
      )}
    >
      <div
        className={cn(
          'flex justify-between bg-neutral-gray-25 rounded-t-md py-2 px-3 items-center',
          status === 'Expired' && 'bg-rejected-0'
        )}
      >
        <div className="flex">
          <Chip type={productPackage?.productName} />
          {isFree && <Chip type="Free" className="ml-2" />}
        </div>
        <div className="flex gap-x-2 items-center">
          <JobBadge status={status} />
          {isSwitchShown && (
            <Switch
              checked={status === 'Active'}
              onChange={handleJobStatusChange}
              className={`${status === 'Active' ? 'bg-[#52B788]' : 'bg-gray-200'} inline-flex h-[24px] w-[43.571px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 items-center ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  status === 'Active' ? 'translate-x-6' : 'translate-x-0'
                } pointer-events-none inline-block h-[17.143px] w-[17.143px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          )}
        </div>
      </div>
      <div
        className={classNames(
          'flex',
          'flex-col',
          'p-4',
          'overflow-auto',
          'gap-[16px]',
          'flex-1'
        )}
      >
        <div className="w-full flex justify-between gap-x-4 text-left">
          <button
            className={classNames('text-left', 'group')}
            type="button"
            onClick={() => {
              jobsActions.setJobDetailDrawerState({
                open: true,
                jobId: id,
              })
            }}
          >
            <p
              className="line-clamp-2 h-fit flex-1 flex-[0.7] text-lg font-medium group-hover:text-primary-blue"
              title={title}
            >
              {title}
            </p>
            <p
              id={`job_listing_title_${index}`}
              className="text-primary-gray text-sm"
            >
              {companyName}
            </p>
          </button>
          <JobCardMenuToggle jobId={id} />
        </div>
        <div className="flex flex-col text-primary-gray text-sm">
          {placementLocations.length <= 0 ||
          !initializeData?.featureToggle.staticLocation ? (
            <div className="flex items-center gap-x-[4px]">
              <MapMarkerIcon />
              {location?.shortAddress}
            </div>
          ) : (
            <>
              <p className="mb-[8px]">Penempatan kerja</p>
              <div className="flex gap-[4px] items-center flex-wrap">
                {placementLocations.length > 0
                  ? placementLocations.map((it) => (
                      <LocationChip key={it.id} text={it.address} />
                    ))
                  : '-'}
              </div>
            </>
          )}
        </div>
        <JobCardMedia medias={medias} jobId={id} />
        <JobCardQuota
          totalQuota={productPackage?.passiveApplicantQuota}
          quotaUsed={metric?.passiveApplicantCount}
          jlType={productPackage?.productName}
          jobId={id}
          status={status}
        />
        {expiryAt && (
          <p className="text-sm text-primary-gray mt-auto">
            Kedaluwarsa pada {formatDateInID(expiryAt, 'dd MMM yyyy')}
          </p>
        )}
      </div>
      <JobCardFooter
        index={index}
        isBoosVisible={Boolean(
          initializeData && initializeData.featureToggle.canBoost
        )}
        canRepost={canRepost}
        status={status}
        onClickCari={handleClickCari}
        onClickBoost={handleClickBoost}
        onClickRepost={handleClickRepost}
        onClickComment={handleClickComment}
        disableBoostButton={!jobPostData.isBoostable}
      />
    </div>
  )
}

const LocationChip = ({ text }: { text: string }) => {
  return (
    <div className="rounded-[6px] bg-[#E3FAFF] text-[12px] flex items-center gap-[4px] p-[4px] w-fit">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9938 6.56884C12.9938 5.35711 12.4676 4.19501 11.5311 3.33818C10.5946 2.48136 9.32441 2 7.99997 2C6.67553 2 5.40534 2.48136 4.46882 3.33818C3.5323 4.19501 3.00617 5.35711 3.00617 6.56884C3.00617 6.56884 2.72335 8.66844 5.98791 13.0081C6.20904 13.3131 6.50923 13.5633 6.86171 13.7364C7.2142 13.9094 7.60802 14 8.00805 14C8.40809 14 8.80191 13.9094 9.1544 13.7364C9.50688 13.5633 9.80707 13.3131 10.0282 13.0081C13.2766 8.66844 12.9938 6.56884 12.9938 6.56884ZM7.99997 8.04743C7.68034 8.04743 7.36788 7.96071 7.10211 7.79824C6.83634 7.63577 6.6292 7.40485 6.50688 7.13467C6.38455 6.8645 6.35255 6.5672 6.41491 6.28038C6.47727 5.99356 6.63119 5.7301 6.85721 5.52332C7.08322 5.31654 7.37119 5.17571 7.68468 5.11866C7.99818 5.06161 8.32313 5.09089 8.61844 5.2028C8.91374 5.31471 9.16615 5.50423 9.34373 5.74738C9.52131 5.99053 9.61609 6.2764 9.61609 6.56884C9.61396 6.9597 9.44274 7.33389 9.13989 7.60959C8.83704 7.88528 8.4272 8.04004 7.99997 8.04004V8.04743Z"
          fill="#00B4D8"
        />
      </svg>
      <p className="text-primary-black">{text}</p>
    </div>
  )
}

export default JobCard
