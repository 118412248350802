import { _delete, patch, post } from '@helpers/fetch-wrapper'
import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  CANDIDATE_BYID_URL_BLOCK,
  CANDIDATE_BYID_URL_REPORT,
  CREATE_EVENT,
  DELETE_COMMENTS_URL,
  EVENT_BY_ID_URL,
  GET_CHANNEL_BY_ID_URL,
  HIRE_CANDIDATE,
  POST_COMMENTS_URL,
  REPORT_COMMENTS_URL,
  SAVE_CHANNEL_URL,
  SEND_EVENT,
  SEND_EVENT_RESULT,
} from '@constants/url'

import { SendInterviewProps, SendInterviewResultProps } from './types'

export const postChannel = createAsyncThunk(
  'chats/postChannel',
  async (jobApplicationId: string, { rejectWithValue }) => {
    try {
      const res = await post(SAVE_CHANNEL_URL(jobApplicationId))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const endChat = createAsyncThunk(
  'chats/endChat',
  async (
    {
      channelId,
      data,
    }: { channelId: string; data: { reason: string; reasonText: string } },
    { rejectWithValue }
  ) => {
    try {
      const res = await _delete(GET_CHANNEL_BY_ID_URL(channelId), data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const reportCandidate = createAsyncThunk(
  'chats/reportCandidate',
  async (
    {
      candidateId,
      data,
    }: {
      candidateId: string
      data: {
        reason: string
        reasonText: string
      }
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await post(CANDIDATE_BYID_URL_REPORT(candidateId), data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const blockCandidate = createAsyncThunk(
  'chats/reportCandidate',
  async (
    {
      candidateId,
      data,
    }: { candidateId: string; data: { reason: string; reasonText: string } },
    { rejectWithValue }
  ) => {
    try {
      const res = await post(CANDIDATE_BYID_URL_BLOCK(candidateId), data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type PostCommentProps = {
  jobPostId: string
  commentText: string
  parentCommentId?: string
}

export const postComment = createAsyncThunk(
  'chats/postComment',
  async (data: PostCommentProps, { rejectWithValue }) => {
    try {
      const commentData: { text: string; parentCommentId?: string } = {
        text: data.commentText,
      }

      if (data.parentCommentId) {
        commentData.parentCommentId = data.parentCommentId
      }

      const res = await post(POST_COMMENTS_URL(data.jobPostId), commentData)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type EditCommentProps = {
  jobPostId: string
  commentText: string
  commentId?: string
  parentCommentId?: string
}

export const editComment = createAsyncThunk(
  'chats/editComment',
  async (data: EditCommentProps, { rejectWithValue }) => {
    try {
      const commentData: { text: string; parentCommentId?: string } = {
        text: data.commentText,
      }

      if (data.parentCommentId) {
        commentData.parentCommentId = data.parentCommentId
      }

      const res = await patch(
        DELETE_COMMENTS_URL(data.jobPostId, data.commentId as string),
        commentData
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type DeleteCommentProps = {
  jobPostId: string
  commentId: string
}

export const deleteComment = createAsyncThunk(
  'chats/deleteComment',
  async (data: DeleteCommentProps, { rejectWithValue }) => {
    try {
      const res = await _delete(
        DELETE_COMMENTS_URL(data.jobPostId, data.commentId)
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type ReportCommentProps = {
  jobPostId: string
  commentId: string
  reason: string
  reasonText?: string
}

export const reportComment = createAsyncThunk(
  'chats/reportComment',
  async (data: ReportCommentProps, { rejectWithValue }) => {
    try {
      const reportData: { reason: string; reasonText?: string } = {
        reason: data.reason,
      }

      if (data.reasonText) {
        reportData.reasonText = data.reasonText
      }

      const res = await post(
        REPORT_COMMENTS_URL(data.jobPostId, data.commentId as string),
        reportData
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createInterview = createAsyncThunk(
  'chat/createInterview',
  async (data: Record<PropertyKey, unknown>, { rejectWithValue }) => {
    try {
      const res = await post(CREATE_EVENT, data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateInterview = createAsyncThunk(
  'chat/updateInterview',
  async (
    {
      eventId,
      reqBody,
    }: { eventId: string; reqBody: Record<PropertyKey, unknown> },
    { rejectWithValue }
  ) => {
    try {
      const res = await patch(EVENT_BY_ID_URL(eventId), reqBody)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const sendInterview = createAsyncThunk(
  'chat/sendInterview',
  async (data: SendInterviewProps, { rejectWithValue }) => {
    try {
      const res = await post(SEND_EVENT(data.eventId), data.data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const sendInterviewResult = createAsyncThunk(
  'chat/sendInterviewResult',
  async (data: SendInterviewResultProps, { rejectWithValue }) => {
    try {
      const res = await post(SEND_EVENT_RESULT(data.eventId), data.data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const hireCandidate = createAsyncThunk(
  'chat/sendInterviewResult',
  async (data: { eventId: string }, { rejectWithValue }) => {
    try {
      const res = await post(HIRE_CANDIDATE(data.eventId))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
